import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import { Avatar, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { cvAPI } from 'api/CvAPI'
import { filesAPI } from 'api/FilesAPI'
import { personAPI } from 'api/PersonAPI'
import { urlAPI } from 'api/UrlAPI'
import { LanguagesData } from 'api/UtilsAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext from 'Components/reusable/DataContext'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import CloseButton from 'Components/reusable/IconButtons/CloseButton'
import { InputField } from 'Components/reusable/InputFields/InputField'
import RegularCheckbox from 'Components/reusable/InputFields/RegularCheckbox'
import { useNotification } from 'Components/reusable/Notification'
import { useCountryData, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICertificate, ICourse, ICv, IEducation, IEmployer, ILanguageSkill, IProject, IUrl } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { CountryCode } from 'types/ids'
import { INetwork } from 'types/networkInterfaces'
import { IOrganizationTranslation, IPerson } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { getCvUrlInitialData } from 'Components/reusable/DataContext/InitialData'
import { cvUrlSchema, profileSchema } from 'Components/reusable/DataContext/ValidationSchema'
import AddButton from 'Components/reusable/IconButtons/AddButton'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import LinkItem from './LinkItem'
import LinkModal from './LinkModal'
import ProfileModal from './ProfileModal'
import ProfileOverview from './ProfileOverview'
import { useIsComponentMounted } from 'hooks/util'

export interface About extends IPerson {
  CVs: ICv[]
  CV: ICv
}

export interface ICvData {
  Employers: IEmployer[]
  Projects: IProject[]
  Education: IEducation[]
  Courses: ICourse[]
  Certificates: ICertificate[]
  LanguageSkills: ILanguageSkill[]
}

/** @notExported  */
interface IProfileCardProps {
  /** Is the editing enabled. */
  editable: boolean
  /** CV data. */
  cv: ICv
  /** Function to update the CV updated at. */
  setUpdatedAt: () => void
  /** CV data. */
  data?: ICvData
  /** Languages data for profile overview. */
  languages: LanguagesData
  /** Viewer networks. */
  viewerNetworks?: INetwork[]
  /** Account networks. */
  accountNetworks?: INetwork[]
  /** Is the profile anonymous. */
  anonymous?: boolean
  /** CV public id. */
  publicId?: string
  /** Function to update the progress of CV. */
  updateProgress?: () => void
}

/**
 * Basic details card for profile.
 *
 * @returns Component for displaying profile basic details.
 * @notExported
 */
const ProfileCard: React.FC<IProfileCardProps> = ({
  editable,
  cv,
  setUpdatedAt,
  data,
  languages,
  viewerNetworks,
  accountNetworks,
  anonymous = false,
  publicId,
  updateProgress,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const { user, groups } = useUser()

  const [modalOpen, setModalOpen] = useState(false)
  const [descriptionEditingOpen, setDescriptionEditingOpen] = useState(false)
  const [primaryRoleEditing, setPrimaryRoleEditing] = useState<boolean>(false)
  const [priceSettingsOpen, setPriceSettingsOpen] = useState(false)
  const [cvWithTranslation, setCvWithTranslation] = useState<ICv>()
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [urls, setUrls] = useState<IUrl[]>([])
  const [url, setUrl] = useState<IUrl>()
  const { countries } = useCountryData()
  const { setNotification } = useNotification()
  const [image, setImage] = useState<string>('')
  const [about, setAbout] = useState<About>()
  const [backendError, setBackendError] = useState<IError>()
  const [role, setRole] = useState<string>()
  const [remoteWorkAvailable, setRemoteWorkAvailable] = useState<boolean>()
  const [minPrice, setMinPrice] = useState<number>(1)
  const [maxPrice, setMaxPrice] = useState<number>(1)
  const [minPriceError, setMinPriceError] = useState<string | undefined>()
  const [maxPriceError, setMaxPriceError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [priceNetworks, setPriceNetworks] = useState<INetwork[]>([])
  const locale = i18n.language
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const controller = new AbortController()

    if (cv.id) {
      ;(async () => {
        try {
          setLoading(true)
          let aboutData
          if (publicId) {
            if (!anonymous) {
              aboutData = await cvAPI.getCvPublicIdAbout(publicId, controller)
            } else {
              aboutData = await cvAPI.getAnonymousAbout(publicId, controller)
            }
          } else {
            aboutData = await cvAPI.getCvAbout(cv.id, controller)
            const priceData = await cvAPI.getNetworkPriceVisible(cv.id, controller)
            if (isComponentMounted.current) setPriceNetworks(priceData)
          }
          if (aboutData && isComponentMounted.current) {
            aboutData.CV = aboutData.CVs.find(currentCv => currentCv.id === cv.id) ?? aboutData.CVs[0]
            aboutData.CV.organizationId = aboutData.organizationId
            if (!aboutData.country) aboutData.country = 'fi' as CountryCode
            setAbout(aboutData)
            if (!anonymous) setCvWithTranslation(aboutData.CV)
            setRole(chooseDBTranslation(i18n, aboutData.CV).primaryRole)
            setRemoteWorkAvailable(aboutData.remoteWorkAvailable)
            if (aboutData.minPrice) setMinPrice(parseInt(aboutData.minPrice.toString()))
            if (aboutData.maxPrice) setMaxPrice(parseInt(aboutData.maxPrice.toString()))
          }
          setLoading(false)
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    }
    if (cv.personId) {
      ;(async () => {
        try {
          if (!anonymous) {
            if (publicId) {
              const image = await filesAPI.downloadProfilePublicId(publicId, controller)
              if (isComponentMounted.current) setImage(image)
            } else {
              const image = await personAPI.getProfileImage(cv.personId, controller)
              if (isComponentMounted.current) setImage(image)
            }
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [cv.personId, cv.id, modalOpen, i18n.language])

  useEffect(() => {
    const controller = new AbortController()

    if (cv.id && !linkModalOpen) {
      ;(async () => {
        try {
          if (!anonymous && !publicId) {
            const results = await urlAPI.getUrls(cv.id, controller)
            if (isComponentMounted.current) setUrls(results)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [cv.id, linkModalOpen])

  const showErrorNotification = () => {
    setNotification({
      message: t('error.header'),
      duration: 'long',
      type: 'error',
    })
  }

  const changeOldImage = (image: string) => {
    setImage(image)
  }

  const editUrl = (url: IUrl) => {
    setUrl(url)
    setLinkModalOpen(true)
  }

  const displayPrices = () => {
    let pricesVisible = false

    if (groups.includes('freelancer') && user?.id === about?.accountId) {
      pricesVisible = true
    } else {
      const commonNetworks = accountNetworks?.filter(
        network => network.id === viewerNetworks?.find(n => n.id === network.id)?.id && network.pricesVisible === true
      )

      if (about?.minPrice || about?.maxPrice) {
        pricesVisible = true
      }

      if (groups.includes('sales')) {
        pricesVisible = true
      } else {
        return (pricesVisible = false)
      }

      if (about?.organizationId === user?.organizationId || (commonNetworks && commonNetworks.length)) {
        pricesVisible = true
      } else {
        pricesVisible = false
      }
    }

    return pricesVisible
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (!cv || !about || !about.CV) return <></>

  let organizationTranslation: IOrganizationTranslation | undefined = undefined

  if (about.Site) {
    if (about.Site.Organization) {
      organizationTranslation = chooseDBTranslation(i18n, about.Site?.Organization)
    }
  }

  const country = countries && about.country ? countries[about.country.toUpperCase()] : null
  const fullName = !anonymous
    ? `${about.firstName} ${about.lastName}`
    : role && role.length > 0
    ? role
    : t('resources.searchResults.missingRole')

  let icons: React.ReactNode[] = []
  if (urls.length > 0) {
    icons = urls.map((url, i) => {
      return <LinkItem key={i} url={url} editUrl={editUrl} />
    })
  }

  const ownSales = user && user.organizationId && about && user.organizationId === about.organizationId ? true : false

  const savePrices = async () => {
    if (minPrice > maxPrice) {
      setMaxPriceError(t('error.biggerThanMin'))
    } else if (minPrice >= 0 && maxPrice >= 0) {
      try {
        await cvAPI.putPrices(cv.id, { minPrice: minPrice, maxPrice: maxPrice })
        setMinPriceError(undefined)
        setMaxPriceError(undefined)
        setPriceSettingsOpen(false)
        setUpdatedAt()
      } catch {
        showErrorNotification()
      }
    } else {
      if (minPrice < 0) {
        setMinPriceError(t('error.positive'))
      } else {
        setMinPriceError(undefined)
      }
      if (maxPrice < 0) {
        setMaxPriceError(t('error.positive'))
      } else {
        setMaxPriceError(undefined)
      }
    }
  }

  const getPriceSettings = (open: boolean) => {
    if (open) {
      return (
        <Grid>
          <Grid container direction="row" spacing={1} item xs={12} alignContent="center" alignItems="center">
            <Grid item>
              <InputField
                type="number"
                value={minPrice}
                onChange={e => setMinPrice(parseInt(e.target.value))}
                error={minPriceError}
                insetLabel
                label={t('profile.modal.minPrice')}
              />
            </Grid>
            <Grid item>
              <RemoveIcon fontSize="small" sx={{ mt: 1 }} />
            </Grid>
            <Grid item>
              <InputField
                type="number"
                value={maxPrice}
                onChange={e => setMaxPrice(parseInt(e.target.value))}
                error={maxPriceError}
                insetLabel
                label={t('profile.modal.maxPrice')}
              />
            </Grid>
            <Grid item>
              <CaleoIconButton
                icon={<CheckIcon color="success" />}
                size="xsmall"
                tooltip={t('save')}
                clickAction={savePrices}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} item xs={12}>
            <Grid item xs={12}>
              {t('profile.modal.priceInfo')}
            </Grid>
            {priceNetworks.length > 0 && (
              <Grid item xs={12}>
                {t('profile.modal.priceNetwork', {
                  networks: priceNetworks.map(network => chooseDBTranslation(i18n, network).name).join(', '),
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container alignItems="center">
        {(ownSales || (groups.includes('freelancer') && user?.id === about?.accountId)) && (
          <>
            <Grid item>
              {maxPrice > 1 || minPrice > 1 ? (
                <>
                  {minPrice} - {maxPrice} €/h
                </>
              ) : (
                <CardContentText type="priceInfo">{t('profile.about.noPrice')}</CardContentText>
              )}
            </Grid>
            <Grid item>
              <EditButton clickAction={() => setPriceSettingsOpen(true)} size="xsmall" noFloat={true} />
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  const saveDescription = async () => {
    setButtonLoading(true)
    setDescriptionEditingOpen(false)

    try {
      const translation = cvWithTranslation?.translations?.find(transl => transl?.Language?.name === locale)

      if (cvWithTranslation !== undefined && translation !== undefined) {
        await cvAPI.putDescription(cvWithTranslation.id, translation)
        if (isComponentMounted.current) {
          setUpdatedAt()
          updateProgress?.()
        }
      }
    } catch {
      showErrorNotification()
    }

    if (isComponentMounted.current) setButtonLoading(false)
  }

  const savePrimaryRole = async () => {
    try {
      if (cvWithTranslation) {
        const translation = cvWithTranslation.translations.find(transl => transl.Language.name === locale)
        setRole(translation?.primaryRole)
        await cvAPI.putRole(cvWithTranslation.id, translation)
        if (isComponentMounted.current) {
          setUpdatedAt()
          updateProgress?.()
          setPrimaryRoleEditing(false)
        }
      }
    } catch {
      showErrorNotification()
    }
  }

  const saveRemoteWork = async () => {
    try {
      await cvAPI.putRemoteWorkAvailability(cv.id, { remoteWorkAllowed: !remoteWorkAvailable })
      if (isComponentMounted.current) setUpdatedAt()
    } catch {
      showErrorNotification()
    }
    if (isComponentMounted.current) setRemoteWorkAvailable(!remoteWorkAvailable)
  }

  return (
    <>
      <Grid m="18px">
        <Grid container spacing={mobileView ? 1 : 2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
                  <Grid item xs={12} md={7}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="h1" color="primary" fontWeight="bold">
                          {fullName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {editable && <EditButton clickAction={() => setModalOpen(true)} size="medium" />}
                      </Grid>
                    </Grid>
                  </Grid>
                  {(displayPrices() || (groups.includes('freelancer') && user?.id === about?.accountId)) && (
                    <Grid item xs={12} md={5}>
                      <Grid container direction="column" alignItems="flex-end">
                        <Grid item>{getPriceSettings(priceSettingsOpen)}</Grid>
                        <Grid item>
                          {(ownSales || (groups.includes('freelancer') && user?.id === about?.accountId)) &&
                          remoteWorkAvailable !== undefined ? (
                            <RegularCheckbox
                              checked={remoteWorkAvailable}
                              onChange={saveRemoteWork}
                              label={t('profile.modal.remoteWorkAvailable')}
                            />
                          ) : about?.remoteWorkAvailable ? (
                            t('profile.modal.remoteWorkAvailable')
                          ) : (
                            t('profile.modal.remoteWorkNotAvailable')
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={12} md={7}>
                    <Grid container direction="row" spacing={mobileView ? 2 : 3} alignItems="center">
                      <Grid item xs={6} sm={undefined}>
                        <Avatar
                          src={image}
                          sx={{ width: mobileView ? 150 : 200, height: mobileView ? 150 : 200 }}
                          alt="ProfilePic"
                        />
                      </Grid>
                      <Grid item xs={6} sm={undefined}>
                        <Grid container spacing={1} direction="column" alignItems="flex-start">
                          <Grid item>{organizationTranslation && organizationTranslation?.name}</Grid>
                          <Grid item>
                            {cvWithTranslation && primaryRoleEditing ? (
                              <Grid container alignItems="center">
                                <Grid item xs={8}>
                                  <DataContext
                                    data={cvWithTranslation}
                                    onChange={newData => setCvWithTranslation(newData)}
                                    localeBase="profile.modal"
                                  >
                                    <DataContext.TranslationImport<ICv> />
                                    <DataContext.TextField<ICv> field="primaryRole" fullWidth hideLabel />
                                  </DataContext>
                                </Grid>
                                <Grid item>
                                  <CloseButton clickAction={() => setPrimaryRoleEditing(false)} size="small" />
                                  <CaleoIconButton
                                    icon={<CheckIcon color="success" />}
                                    size="xsmall"
                                    tooltip={t('save')}
                                    clickAction={savePrimaryRole}
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container justifyContent="flex-start" alignItems="center">
                                {!anonymous && (
                                  <Typography fontSize={26} fontWeight="bold">
                                    {role && role?.length > 0 ? role : t('noRoleSet')}
                                  </Typography>
                                )}
                                {editable && !loading && (
                                  <EditButton
                                    clickAction={() => setPrimaryRoleEditing(true)}
                                    size="xsmall"
                                    noFloat={true}
                                  />
                                )}
                              </Grid>
                            )}
                          </Grid>
                          <Grid item>{about.telephone}</Grid>
                          <Grid item>
                            <Grid lineHeight="17px" fontSize="18px">
                              {about.postalCode} {about.city}
                              {about.city && country && ','} {country}
                            </Grid>
                          </Grid>
                          {!anonymous && (
                            <Grid item>
                              <Grid container alignItems="center">
                                <Grid item>{icons}</Grid>
                                <Grid item>
                                  {editable && (
                                    <AddButton
                                      clickAction={() => setLinkModalOpen(true)}
                                      tooltip={t('profile.about.addLinkTooltip')}
                                      size="xsmall"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <ProfileOverview data={data} languages={languages} publicId={publicId} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!anonymous && (
            <Grid item xs={12}>
              {descriptionEditingOpen && cvWithTranslation ? (
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <DataContext
                      data={cvWithTranslation}
                      onChange={newData => setCvWithTranslation(newData)}
                      localeBase="profile.modal"
                    >
                      <DataContext.TranslationImport<ICv> />
                      <DataContext.TextField<ICv>
                        field="about"
                        fullWidth
                        rows={10}
                        multiline
                        maxHintLength={1500}
                        grow
                        insetLabel
                      />
                    </DataContext>
                  </Grid>
                  <Grid item>
                    <Button onClick={saveDescription} color="primary" variant="contained" disabled={buttonLoading}>
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <CardContentText type="profileDescription">
                  <p style={{ display: 'inline' }}>{chooseDBTranslation(i18n, cvWithTranslation)?.about}</p>
                  <div style={{ display: 'inline', marginLeft: 10 }}>
                    {editable && (
                      <EditButton clickAction={() => setDescriptionEditingOpen(true)} size="xsmall" noFloat={true} />
                    )}
                  </div>
                </CardContentText>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {modalOpen && (
        <ProfileModal
          item={about}
          schema={profileSchema()}
          maxWidth="md"
          fullWidth
          localeBase={'profile.modal'}
          cvId={cv.id}
          countries={countries}
          oldImage={image}
          personId={cv.personId}
          changeOldImage={changeOldImage}
          onClose={() => {
            setUpdatedAt()
            setModalOpen(false)
          }}
          disableDelete={true}
          submitOnModal={true}
          header="about"
        />
      )}
      {linkModalOpen && (
        <LinkModal
          onClose={({ newItem }) => {
            setLinkModalOpen(false)
            setUrl(undefined)
            if (newItem) {
              setUpdatedAt()
            }
          }}
          item={url}
          cvId={cv.id}
          initialData={getCvUrlInitialData(cv.id)}
          schema={cvUrlSchema()}
          api={urlAPI}
          localeBase="url.modal"
          maxWidth="xs"
          fullWidth={true}
        />
      )}
    </>
  )
}

export default ProfileCard
