import { Box, Popper } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { skillAPI } from 'api/SkillAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useIsComponentMounted } from 'hooks/util'
import { InputField } from '../InputField'
import { orderBy } from 'lodash'

type SingleValue = {
  multiple?: false
  value: ISkillOrIndustryOrRole | null
  onChange: (newValue: ISkillOrIndustryOrRole | null) => void
}

type MultiValue = {
  multiple: true
  value: ISkillOrIndustryOrRole[]
  onChange: (newValue: ISkillOrIndustryOrRole[]) => void
}

type Value = SingleValue | MultiValue

/**
 * Skill picker component.
 *
 * @param label - The label text.
 * @param size - The size of the component.
 * @param required - Whether the field is required.
 * @param disabled - Whether the field is disabled.
 * @param name - The name of the field.
 * @param usedSkills - The used skills.
 * @param noPlaceholders - Whether to show placeholders.
 * @param placeholderLabel - The placeholder label text.
 * @param placeholderText - The placeholder text.
 * @param onKeyDown - The onKeyDown event handler.
 * @param type - The type of the skill.
 * @param adminView - Whether the field is for admin view.
 * @param icon - The icon of the field.
 * @param disableClearable - Whether the field is disableClearable.
 * @param search - Whether the field is searchable.
 * @param showUnpromoted - Whether to show unpromoted skills.
 * @returns The skill picker component.
 * @notExported
 */
const SkillPicker: React.FC<
  Value & {
    label?: string
    size?: 'small' | 'medium'
    required?: boolean
    disabled?: boolean
    name?: string
    usedSkills?: ISkillOrIndustryOrRole[]
    usedSkillIds?: number[]
    noPlaceholders?: boolean
    placeholderLabel?: string
    placeholderText?: string
    onKeyDown?: (e) => void
    type: string
    adminView?: boolean
    icon?: JSX.Element
    disableClearable?: boolean
    search?: boolean
    showUnpromoted?: boolean
    options?: ISkillOrIndustryOrRole[]
    insetLabel?: boolean
    resetOnSelect?: boolean
    sx?: object
  }
> = ({
  multiple,
  size = 'small',
  value,
  onChange,
  label = 'Skill',
  required = false,
  disabled = false,
  name,
  usedSkills = [],
  usedSkillIds = [],
  noPlaceholders = false,
  placeholderLabel,
  placeholderText,
  onKeyDown,
  type,
  adminView = false,
  icon,
  disableClearable = false,
  search = false,
  showUnpromoted = false,
  options,
  insetLabel,
  resetOnSelect = false,
  sx,
}) => {
  const { t, i18n } = useTranslation()
  const isComponentMounted = useIsComponentMounted()

  const [backendError, setBackendError] = useState<IError>()
  const [skills, setSkills] = useState<ISkillOrIndustryOrRole[]>([])
  const [unpromotedSkills, setUnpromotedSkills] = useState<ISkillOrIndustryOrRole[]>([])
  const [nameValue, setNameValue] = useState<string | undefined>(name ?? '')
  const [helperText, setHelperText] = useState<string>()
  const [componentValue, setComponentValue] = useState<Value['value']>(value ?? null)

  useEffect(() => {
    const controller = new AbortController()

    if (options) {
      setUnpromotedSkills(options)
      setSkills(options)
      return
    }

    if (showUnpromoted && unpromotedSkills.length > 0) {
      return
    }

    if (!showUnpromoted && skills.length > 0) {
      return
    }

    ;(async () => {
      try {
        let newSkills: ISkillOrIndustryOrRole[] = []

        if (type === 'skill') {
          if (showUnpromoted) {
            newSkills = await skillAPI.getAllUnpromotedSkills(controller)
          } else {
            newSkills = await skillAPI.getAllSkills(controller)
          }
        }

        if (type === 'industry') {
          if (showUnpromoted) {
            newSkills = await skillAPI.getAllUnpromotedIndustries(controller)
          } else {
            newSkills = await skillAPI.getAllIndustries(controller)
          }
        }

        if (type === 'role') {
          if (showUnpromoted) {
            newSkills = await skillAPI.getAllUnpromotedRoles(controller)
          } else {
            newSkills = await skillAPI.getAllRoles(controller)
          }
        }

        if (type === 'all') {
          if (showUnpromoted) {
            newSkills = await skillAPI.getAllUnpromoted(controller)
          } else {
            newSkills = await skillAPI.getAll(controller)
          }
        }

        if (!isComponentMounted.current) return

        if (noPlaceholders) newSkills = newSkills.filter(skill => skill.isPromoted === true)

        if (showUnpromoted) {
          const orderedSkills = orderBy(newSkills, [skill => chooseDBTranslation(i18n, skill).name], ['asc'])
          setUnpromotedSkills(orderedSkills)
        } else {
          const orderedSkills = orderBy(newSkills, [skill => chooseDBTranslation(i18n, skill).name], ['asc'])
          setSkills(orderedSkills)
        }
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (name) {
      setNameValue(name)
    }
  }, [name])

  const createSkill = async (name: string) => {
    let newItem: ISkillOrIndustryOrRole
    if (type === 'skill') {
      newItem = await skillAPI.createSkill({ name, language: i18n.language, kind: 'skill' })
    } else if (type === 'industry') {
      newItem = await skillAPI.createSkill({ name, language: i18n.language, kind: 'industry' })
    } else {
      newItem = await skillAPI.createSkill({ name, language: i18n.language, kind: 'role' })
    }

    setSkills([...skills, newItem])
    return newItem
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const getLabel = (item: ISkillOrIndustryOrRole) => {
    const name = chooseDBTranslation(i18n, item).name
    const languages: string[] = []
    for (const currentItem of item.translations) {
      languages.push(currentItem.Language.name.toUpperCase())
    }
    return `${name} (${languages}) `
  }

  const PopperMy = function (props) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />
  }

  return (
    <>
      {!placeholderLabel && !insetLabel && <CaleoInputLabel icon={icon} label={label} required={required} />}
      <Autocomplete
        PopperComponent={PopperMy}
        selectOnFocus
        clearOnBlur
        disableClearable={disableClearable}
        blurOnSelect
        handleHomeEndKeys
        multiple={multiple}
        disabled={disabled}
        inputValue={nameValue}
        value={(componentValue as typeof componentValue & string) ?? ''}
        onKeyDown={e => onKeyDown && onKeyDown(e)}
        options={
          showUnpromoted
            ? (unpromotedSkills as ((typeof skills)[0] | string)[])
            : (skills as ((typeof skills)[0] | string)[])
        }
        getOptionDisabled={option =>
          usedSkills.some(skill => skill.id === (option as unknown as ISkillOrIndustryOrRole)?.id) ||
          (typeof option !== 'string' && usedSkillIds.includes(option.id))
        }
        onChange={async (_event, newValue) => {
          if (typeof newValue === 'string' || Array.isArray(newValue)) {
            let newSkill: ISkillOrIndustryOrRole[] | ISkillOrIndustryOrRole
            if (Array.isArray(newValue)) {
              newSkill = []
              for (const skill of newValue) {
                if (typeof skill === 'string') {
                  newSkill.push(await createSkill(skill as string))
                } else if (skill !== null) {
                  newSkill.push(skill)
                }
              }
            } else {
              newSkill = await createSkill(newValue)
            }
            onChange(
              (multiple
                ? [...(newSkill as ISkillOrIndustryOrRole[])]
                : (newSkill as ISkillOrIndustryOrRole)) as unknown as ISkillOrIndustryOrRole & ISkillOrIndustryOrRole[]
            )
          } else {
            if (!newValue) {
              setNameValue('')
            } else {
              setNameValue(chooseDBTranslation(i18n, newValue).name)
            }
            onChange(newValue as unknown as ISkillOrIndustryOrRole & ISkillOrIndustryOrRole[])
          }
          setHelperText(undefined)
          if (resetOnSelect) {
            setComponentValue(null)
            setNameValue('')
          }
        }}
        filterOptions={(options, { inputValue }) => {
          const result = fuseFiltering(options, inputValue, ['translations.name'])

          if (!noPlaceholders && inputValue && inputValue.length > 0) {
            const filteredSkills = skills.filter(
              skill => chooseDBTranslation(i18n, skill).name.toLowerCase() === inputValue.toLowerCase()
            )
            if (!filteredSkills.length) result.push(inputValue)
          }

          return result
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          return adminView ? getLabel(option) : chooseDBTranslation(i18n, option).name
        }}
        isOptionEqualToValue={(option, value) => {
          if (typeof option === 'string' || typeof value === 'string') {
            return false
          }

          return option.id === value.id
        }}
        renderOption={(props, option) => {
          if (typeof option === 'string') {
            return (
              <Box component="li" {...props}>
                {`${t(`placeholder.${type}`)} "${option}"`}
              </Box>
            )
          }

          return (
            <Box component="li" {...props} key={option.id}>
              {adminView ? getLabel(option) : chooseDBTranslation(i18n, option).name}
              {option.trend && Math.sign(option.trend) === 1 && (
                <ArrowUpwardIcon color="success" fontSize="small" style={{ marginLeft: 5 }} />
              )}
              {option.trend && Math.sign(option.trend) === -1 && (
                <ArrowDownwardIcon color="error" fontSize="small" style={{ marginLeft: 5 }} />
              )}
            </Box>
          )
        }}
        renderInput={params => (
          <>
            {helperText && (
              <span
                style={{
                  fontSize: '0.75rem',
                  marginTop: 4,
                }}
              >
                {helperText}
              </span>
            )}
            <InputField
              {...params}
              fullWidth
              placeholder={placeholderLabel ?? placeholderText}
              margin="dense"
              size={size}
              onChange={event => {
                if (
                  !search &&
                  typeof event.target.value === 'string' &&
                  (event.target.value.toLowerCase().includes(',') ||
                    event.target.value.toLowerCase().includes('/') ||
                    event.target.value.toLowerCase().includes(' ja ') ||
                    event.target.value.toLowerCase().includes(' and ') ||
                    event.target.value.toLowerCase().includes('\\'))
                ) {
                  setHelperText(t('skillWarning', { type: t(`${type}Lower`) }))
                } else {
                  setHelperText(undefined)
                }

                setNameValue(event.target.value)
              }}
              variant={placeholderLabel && !placeholderText ? 'standard' : 'outlined'}
              insetLabel={insetLabel}
              label={insetLabel ? label : undefined}
              InputLabelProps={{ shrink: insetLabel ? true : undefined }}
              required={required}
            />
          </>
        )}
        sx={sx}
      />
    </>
  )
}

export default SkillPicker
