import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Grid, styled, Toolbar } from '@mui/material'
import caleoLogo from 'assets/images/caleoLogoSmallBlack.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { ITermsAndPolicy } from 'types/termsAndPolicyInterfaces'
import { termsAndPolicyApi } from 'api/TermsAndPolicyAPI'
import { chooseDBTranslation } from 'utils/translations'
import TermsAndPolicyModal from 'Components/reusable/TermsAndPolicyModal/TermsAndPolicyModal'
import { useUser } from 'hooks'
import SalesPoolModal from 'Components/General/SalesPoolModal/SalesPoolModal'
import { IError } from 'types/error'
import { useIsComponentMounted } from 'hooks/util'

const SpanLink = styled('span')(({ theme }) => ({
  marginRight: 10,
  cursor: 'pointer',
  color: theme.palette.neutral.main,
  fontFamily: 'Helvetica',
  fontSize: 17,
  textDecoration: 'none',

  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
    marginRight: 5,
  },
}))

const AnchorLink = styled('a')(({ theme }) => ({
  marginRight: 10,
  cursor: 'pointer',
  color: theme.palette.neutral.main,
  fontFamily: 'Helvetica',
  fontSize: 17,
  textDecoration: 'none',

  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
    marginRight: 5,
  },
}))

const ImgLink = styled('img')(({ theme }) => ({
  marginRight: 10,
  cursor: 'pointer',
  color: theme.palette.neutral.main,
  fontFamily: 'Helvetica',
  fontSize: 17,
  textDecoration: 'none',

  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
    marginRight: 5,
  },
}))

/**
 * Footer component.
 *
 * @returns Footer component.
 * @notExported
 */
const Footer: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, groups, features } = useUser()
  const [termsAndPolicies, setTermsAndPolicies] = useState<ITermsAndPolicy[]>([])
  const [selectedItem, setSelectedItem] = useState<ITermsAndPolicy | null | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      try {
        const termsAndPolicies = await termsAndPolicyApi.getGlobalTermsAndPolicies(controller)
        if (isComponentMounted.current) setTermsAndPolicies(termsAndPolicies)
      } catch (error) {
        console.error(error as IError)
      }

      return () => {
        controller.abort()
      }
    })()
  }, [])

  const handleLogoClick = () => {
    groups.includes('sales') ? navigate('/employees') : navigate('/profile')
  }

  let hideToolbar = false
  if (
    location.pathname.includes('/reset-password') ||
    location.pathname.includes('/forgot-password') ||
    location.pathname.includes('/change-password') ||
    location.pathname.includes('/login') ||
    location.pathname.includes('/register') ||
    location.pathname.includes('/confirm') ||
    location.pathname.includes('/activate') ||
    location.pathname.includes('/terms') ||
    location.pathname.includes('/privacy-policy')
  ) {
    hideToolbar = true
  }

  return (
    <>
      {!hideToolbar && (
        <>
          <Toolbar sx={{ height: 'auto', width: '100%' }}>
            <Grid container alignItems="baseline" direction="row">
              <Grid item xs={8} sm={4} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <div>
                  {termsAndPolicies.map(item => {
                    return (
                      <SpanLink key={item.id} onClick={() => setSelectedItem(item)}>
                        {chooseDBTranslation(i18n, item).title}
                      </SpanLink>
                    )
                  })}
                  <AnchorLink href="mailto:support@caleo-rm.com">{t('footer.support')}</AnchorLink>
                </div>
              </Grid>
              {user && features && features.includes('salespool') && (
                <Grid item xs>
                  <SalesPoolModal />
                </Grid>
              )}
            </Grid>
            <ImgLink src={caleoLogo} alt="logo" onClick={handleLogoClick} />
          </Toolbar>
          {selectedItem !== undefined && (
            <TermsAndPolicyModal
              item={selectedItem}
              onClose={() => setSelectedItem(undefined)}
              header={chooseDBTranslation(i18n, selectedItem)?.title}
            />
          )}
        </>
      )}
    </>
  )
}

export default Footer
