import { cvReferencesAPI } from 'api/CvAPI'
import React, { useMemo } from 'react'
import { ICv, IReference } from 'types/cvsInterfaces'
import withProfileCard from 'Components/reusable/HOC/withProfileCard'
import { getReferenceInitialData } from 'Components/reusable/DataContext/InitialData'
import { referenceSchema } from 'Components/reusable/DataContext/ValidationSchema'
import ReferenceItemCard from './ReferenceItemCard'
import ReferenceModal from './ReferenceModal'

/** @notExported */
interface IReferencesCardProps {
  editable: boolean // Indicates if the card is editable
  cv: ICv // The CV object
  setUpdatedAt: () => void // Callback function to set the updated date
  closeItem: () => void // Callback function to close an item
  replaceOrAddItem: (newItem: IReference) => void // Callback function to replace or add an item
  openItem: (item: IReference) => void // Callback function to open an item
  editedItem: IReference | null // The currently edited item
  items: IReference[] // The list of items
  header: string // The header text
}

/**
 * The ReferencesCard component.
 *
 * @param editable - Indicates if the card is editable
 * @param cv - The CV object
 * @param setUpdatedAt - Callback function to set the updated date
 * @param closeItem - Callback function to close an item
 * @param replaceOrAddItem - Callback function to replace or add an item
 * @param openItem - Callback function to open an item
 * @param editedItem - The currently edited item
 * @param items - The list of items
 * @param header - The header text
 * @returns The ReferencesCard component.
 * @notExported
 */
const ReferencesCard: React.FC<IReferencesCardProps> = ({
  cv,
  editable,
  items,
  setUpdatedAt,
  closeItem,
  replaceOrAddItem,
  openItem,
  editedItem,
  header,
}) => {
  const itemCards = useMemo(
    () =>
      items.map((item, i) => (
        <ReferenceItemCard
          key={item.id}
          editable={editable}
          item={item}
          onOpen={() => openItem(item)}
          header={header}
          showDivider={i < items.length - 1}
        />
      )),
    [items]
  )

  return (
    <>
      {itemCards}
      {editedItem !== undefined && (
        <ReferenceModal
          key={editedItem?.id ?? 'new'}
          item={editedItem}
          cvId={cv.id}
          initialData={getReferenceInitialData(cv.id)}
          schema={referenceSchema()}
          api={cvReferencesAPI}
          localeBase="profile.reference.modal"
          maxWidth="xl"
          onClose={({ newItem }) => {
            closeItem()
            if (newItem) {
              setUpdatedAt()
              replaceOrAddItem(newItem)
            }
          }}
          header={header}
        />
      )}
    </>
  )
}

export default withProfileCard(ReferencesCard)
