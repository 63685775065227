import { cvLanguageSkillsAPI } from 'api/CvAPI'
import { LanguagesData } from 'api/UtilsAPI'
import { useLocalStorage } from 'hooks/data'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICv, ILanguageSkill } from 'types/cvsInterfaces'
import { getLanguageInitialData } from 'Components/reusable/DataContext/InitialData'
import { languageSchema } from 'Components/reusable/DataContext/ValidationSchema'
import withProfileCard from 'Components/reusable/HOC/withProfileCard'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import LanguageModal from './LanguageModal'
import SkillLevelChip from 'Components/reusable/CaleoCustomComponents/SkillLevelChip'
import { ColumnDef, PaginationState, VisibilityState } from '@tanstack/react-table'

/** @notExported  */
interface ILanguageCardProps {
  /** Is card editable. */
  editable: boolean
  /** CV item. */
  cv: ICv
  /** Function for setting updated date. */
  setUpdatedAt: () => void
  /** Function for replacing or adding item. */
  replaceOrAddItem: (newItem) => ILanguageSkill[]
  /** Function for replacing or adding items. */
  replaceOrAddItems: (incomingItems) => ILanguageSkill[]
  /** Function for opening item. */
  openItem: (item) => void
  /** Edited item. */
  editedItem: ILanguageSkill | null
  /** Function for closing item. */
  closeItem: () => void
  /** Items. */
  items: ILanguageSkill[]
  /** Header text. */
  header: string
  /** Languages. */
  languages: LanguagesData
  /** Function for updating overview data. */
  updateOverviewData: (type: string, data: unknown | unknown[]) => void
  /** Function for updating progress. */
  updateProgress: () => void
}

/**
 * Language skill card.
 *
 * @returns Language skill card component.
 * @notExported
 */
const LanguageCard: React.FC<ILanguageCardProps> = ({
  cv,
  editable,
  setUpdatedAt,
  closeItem,
  replaceOrAddItem,
  replaceOrAddItems,
  openItem,
  editedItem,
  items,
  header,
  languages,
  updateOverviewData,
  updateProgress,
}) => {
  const { t, i18n } = useTranslation()
  const [languageCount, setLanguageCount] = useLocalStorage('languageCount', 10)
  const [updatedData, setUpdatedData] = useState<ILanguageSkill[]>()
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: languageCount ? languageCount : 25,
  })

  useEffect(() => {
    if (updatedData) {
      updateOverviewData('languages', updatedData)
      updateProgress()
    }
  }, [updatedData])

  const columns = React.useMemo<ColumnDef<ILanguageSkill>[]>(
    () => [
      {
        id: 'name',
        header: t('language.name'),
        accessorFn: row => languages?.[row.languageCode] ?? '',
      },
      {
        id: 'level',
        header: t('language.level'),
        accessorFn: row => `${row.level}`,
        cell: ({ row }) => <SkillLevelChip level={row.original.level ?? 0} type="language" />,
      },
      {
        id: 'controls',
        enableHiding: false,
        cell: ({ row }) => {
          if (editable) {
            return <EditButton clickAction={() => openItem(row.original)} />
          } else {
            return ''
          }
        },
      },
    ],
    [editable, t, languages, i18n.language]
  )

  return (
    <>
      <CustomTable
        columns={columns}
        data={items}
        rowCount={languageCount}
        setRowCount={setLanguageCount}
        initialPageSize={10}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        setPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        elevation={0}
        newStyle
      />
      {editedItem !== undefined && (
        <LanguageModal
          item={editedItem}
          cvId={cv.id}
          onClose={({ newItem }) => {
            closeItem()
            if (newItem) {
              setUpdatedAt()
              updateProgress()
              if (Array.isArray(newItem)) {
                setUpdatedData(replaceOrAddItems(newItem))
              } else {
                setUpdatedData(replaceOrAddItem(newItem))
              }
            }
          }}
          languages={languages}
          items={items}
          schema={languageSchema()}
          initialData={getLanguageInitialData(cv.id)}
          localeBase="language"
          maxWidth="md"
          api={cvLanguageSkillsAPI}
          header={header}
          hideLanguageHelperText={true}
          addSelected
          checkFields={['languageCode', 'level']}
        />
      )}
    </>
  )
}

export default withProfileCard(LanguageCard)
