import { Checkbox, FormControlLabel, Grid, Link } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { authAPI } from 'api/AuthAPI'
import { termsAndPolicyApi } from 'api/TermsAndPolicyAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import LoginPageCard from 'Components/reusable/CaleoCustomComponents/LoginPageCard'
import LoginPageCardWrapper from 'Components/reusable/CaleoCustomComponents/LoginPageCardWrapper'
import ModalPaper from 'Components/reusable/CaleoCustomComponents/ModalPaper'
import { useNotification } from 'Components/reusable/Notification'
import { useLogin } from 'hooks'
import i18n from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { IError } from 'types/error'
import { ITermsAndPolicy } from 'types/termsAndPolicyInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import TermsAndPolicyModal from 'Components/reusable/TermsAndPolicyModal/TermsAndPolicyModal'
import { useLoading } from 'Components/reusable/LoadingProvider'
import { STORAGE_AZURE_TOKEN_KEY_NAME, STORAGE_TOKEN_KEY_NAME } from 'constants/index'
import { useIsComponentMounted } from 'hooks/util'

/**
 * Renders the ActivateAccount component.
 *
 * @return {ReactNode} The rendered ReactNode.
 * @notExported
 */
const ActivateAccount: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { setNotification } = useNotification()
  const { doLogin, getAzureUser } = useLogin()

  const [termsAndPolicies, setTermsAndPolicies] = useState<ITermsAndPolicy[]>([])
  const [acceptedTermsAndPolicies, setAcceptedTermsAndPolicies] = useState<number[]>([])
  const [buttonsEnabled, setButtonsEnabled] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [backendError, setBackendError] = useState<IError>()
  const [selectedItem, setSelectedItem] = useState<ITermsAndPolicy | null | undefined>(undefined)
  const { startLoading, endLoading } = useLoading()

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => {
      try {
        startLoading('activateAccount')
        if (
          location.state &&
          location.state?.userData?.accountId &&
          location.state?.password &&
          location.state?.token
        ) {
          const termsAndPolicies = await termsAndPolicyApi.getGlobalTermsAndPolicies(controller)
          if (!isComponentMounted.current) return
          setTermsAndPolicies(termsAndPolicies)
        } else if (location.state && location.state?.termsNotAccepted && location.state?.userData?.userId) {
          const termsAndPolicies = await termsAndPolicyApi.getGlobalTermsAndPolicies(controller)
          const userTermsAndPolicies = await termsAndPolicyApi.getUsersTermsAndPolicies(
            location.state?.userData?.userId,
            controller
          )

          if (!isComponentMounted.current) return
          const unacceptedPolicies = termsAndPolicies.filter(
            item => !userTermsAndPolicies.find(({ termsAndPolicyId }) => item.id === termsAndPolicyId)
          )
          setTermsAndPolicies(unacceptedPolicies)
        } else {
          navigate('/login')
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        endLoading('activateAccount')
      }
    })()

    return () => {
      controller.abort()
    }
  }, [location])

  const handleTermsAndPolicyAcceptedChange = (termsAndPolicyId: number) => {
    const index = acceptedTermsAndPolicies.indexOf(termsAndPolicyId)
    if (index < 0) {
      setAcceptedTermsAndPolicies([...acceptedTermsAndPolicies, termsAndPolicyId])
    } else {
      setAcceptedTermsAndPolicies(acceptedTermsAndPolicies.filter(accepted => accepted !== termsAndPolicyId))
    }
  }

  const handleSave = async () => {
    try {
      setButtonsEnabled(false)
      if (location.state && location.state?.termsNotAccepted && location.state?.loginDetails) {
        const acceptanceData = {
          acceptances: acceptedTermsAndPolicies,
        }
        const accountId = location.state?.userData?.userId
        await termsAndPolicyApi.updateAcceptances(accountId, acceptanceData)
        if (localStorage.getItem(STORAGE_TOKEN_KEY_NAME) && !localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME)) {
          await doLogin(location.state?.loginDetails)
        } else {
          await getAzureUser()
        }
        navigate('/profile')
      } else {
        const acceptanceData = {
          acceptances: acceptedTermsAndPolicies,
        }
        const activationData = {
          password: location.state?.password,
          token: location.state?.token,
        }
        const accountId = location.state?.userData?.accountId

        await termsAndPolicyApi.updateAcceptances(accountId, acceptanceData)
        await authAPI.activate(activationData)

        setNotification({
          message: t('accountActivatedMessage'),
          type: 'success',
          duration: 'long',
        })
        navigate('/login')
      }
    } catch (error) {
      const err = error as IError
      setButtonsEnabled(true)
      if (err.response?.status === 400 && err.response?.data === 'same-password-error') {
        navigate(`/confirm/${location.state?.token}`, { state: { error: err.response?.data } })
      } else {
        setError(err.message ?? '')
        setBackendError(error as IError)
      }
    }
  }

  let acceptanceMissing = false
  termsAndPolicies.forEach(item => {
    if (acceptedTermsAndPolicies.indexOf(item.id) < 0) {
      acceptanceMissing = true
    }
  })

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <LoginPageCardWrapper>
      <LoginPageCard header={t('password.activateAccount')} avatar={<CheckCircleIcon />}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
            <ModalPaper>
              <CardContentText type="header">{t('acceptFollowingTerms')}:</CardContentText>
              {termsAndPolicies.map(item => {
                return (
                  <div key={item.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptedTermsAndPolicies.includes(item.id)}
                          onChange={() => handleTermsAndPolicyAcceptedChange(item.id)}
                          name="checkbox"
                          color="secondary"
                        />
                      }
                      label={
                        <Link
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            setSelectedItem(item)
                          }}
                        >
                          {chooseDBTranslation(i18n, item).title}
                        </Link>
                      }
                    />
                  </div>
                )
              })}
            </ModalPaper>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <CardContentText type="error">{error}</CardContentText>
        </Grid>
        <Grid container justifyContent="flex-end">
          <CaleoPrimaryButton
            clickAction={handleSave}
            label={t('password.activate')}
            valid={!buttonsEnabled || !acceptanceMissing}
          />
        </Grid>
      </LoginPageCard>
      {selectedItem !== undefined && (
        <TermsAndPolicyModal
          item={selectedItem}
          onClose={() => setSelectedItem(undefined)}
          header={chooseDBTranslation(i18n, selectedItem)?.title}
        />
      )}
    </LoginPageCardWrapper>
  )
}

export default ActivateAccount
