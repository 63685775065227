import { Grid } from '@mui/material'
import BorderedSection from 'Components/reusable/CaleoCustomComponents/BorderedSection'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchResult, ISearchFields } from 'types/searchInterfaces'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { getCV } from 'utils/getCV'
import AssignmentProposalModal from 'Components/General/AssignmentProposalModal/AssignmentProposalModal'
import { assignmentProposalSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { getAssignmentProposalInitialData } from 'Components/reusable/DataContext/InitialData'
import { CountriesData } from 'api/UtilsAPI'
import ResultContent from './ResultContent'
import colors from 'constants/colors'

/** @notExported */
interface IResultCardProps {
  /** The search result. */
  result: ISearchResult
  /** The search terms. */
  terms: ISearchFields
  /** The list of translated country options. */
  countries: CountriesData
}

/**
 * Renders the result card component with specific content based on the result kind.
 *
 * @param {IResultCardProps} result - the result object to be displayed
 * @param {ISearchFields} terms - the search terms used to obtain the result
 * @param {CountriesData} countries - the CountriesData used for rendering the result
 * @return {JSX.Element} the result card component
 */
const ResultCard: React.FC<IResultCardProps> = ({ result, terms, countries }) => {
  const { t } = useTranslation()
  const [openProposal, setOpenProposal] = useState<string>()

  const cv = result.Person.CVs ? result.Person.CVs[0] : getCV(result.Person, terms)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {result.kind === 'exact' && (
          <BorderedSection color="#0D6AFF" icon={AutoAwesomeIcon} title={t('search.exactMatch')}>
            <ResultContent
              result={result}
              cv={cv}
              countries={countries}
              terms={terms}
              setOpenProposal={setOpenProposal}
            />
          </BorderedSection>
        )}
        {result.kind === 'partial' && (
          <Grid
            item
            xs={12}
            sx={{
              border: `1px solid ${colors.borderColor}`,
              borderRadius: '5px',
              padding: '1em',
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <ResultContent
              result={result}
              cv={cv}
              countries={countries}
              terms={terms}
              setOpenProposal={setOpenProposal}
            />
          </Grid>
        )}
      </Grid>
      {openProposal && (
        <AssignmentProposalModal
          anonymousId={openProposal}
          onClose={() => {
            setOpenProposal(undefined)
          }}
          initialData={getAssignmentProposalInitialData()}
          schema={assignmentProposalSchema()}
          localeBase="search.propose"
          submitOnModal={true}
          hideLanguageHelperText={true}
          maxWidth="md"
          fullWidth={true}
          customButtons={true}
        />
      )}
    </Grid>
  )
}

export default ResultCard
