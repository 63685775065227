import { CardHeader, CircularProgress, Grid } from '@mui/material'
import AllocationIcon from '@mui/icons-material/CalendarToday'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { allocationAPI } from 'api/AllocationAPI'
import { personAPI } from 'api/PersonAPI'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import { getAllocationInitialData } from 'Components/reusable/DataContext/InitialData'
import { ownAllocationSchema } from 'Components/reusable/DataContext/ValidationSchema'
import colors from 'constants/colors'
import { useUser } from 'hooks'
import { useAllocations } from 'hooks/allocations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocation } from 'types/allocationInterfaces'
import { IPerson } from 'types/userInterfaces'
import AllocationCalendar from '../components/AllocationCalendar/AllocationCalendar'
import AllocationModal from '../components/AllocationModal'
import AllocationTable from 'Components/General/AllocationTable/AllocationTable'
import AllocationTimeline from 'Components/reusable/AllocationTimeline/AllocationTimeline'
import _ from 'lodash'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { IError } from 'types/error'
import { useCompanyPermissions, useTeamLeaderPermissions } from 'hooks/permissions'
import GreenAddButton from 'Components/reusable/Buttons/GreenAddButton'
import { useIsComponentMounted } from 'hooks/util'
import { useParams } from 'react-router-dom'

/** @notExported */
interface IPersonAllocationViewProps {
  /** Is view collapsible */
  collapsible?: boolean
}

/**
 * Person allocation view.
 *
 * @returns Person allocation view coponent.
 * @notExported
 */
const PersonAllocationView: React.FC<IPersonAllocationViewProps> = ({ collapsible = false }) => {
  const isComponentMounted = useIsComponentMounted()
  const { user, groups } = useUser()
  const { t } = useTranslation()

  const [editedItem, setEditedItem] = useState<IAllocation | null | undefined>(undefined)
  const [person, setPerson] = useState<IPerson>()
  const [showMore, setShowMore] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()

  const { profileId } = useParams()

  const personId = profileId ? parseInt(profileId, 10) : user?.Person?.id ?? 0
  const { allocations, setUpdate, loading } = useAllocations(personId)
  const { salesAccess } = useCompanyPermissions(person?.organizationId)
  const { teamLeaderAccess } = useTeamLeaderPermissions()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (personId) {
        setUpdate(true)
        try {
          const person = await personAPI.getPersonData(personId, controller)
          if (!isComponentMounted.current) return
          setPerson(person)
        } catch (error) {
          setBackendError(error as IError)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [personId])

  const checkCollapse = () => {
    if ((collapsible && showMore) || collapsible === false) {
      return true
    }
    return false
  }

  const checkPermissions = () => {
    if (groups.includes('admin') || salesAccess || teamLeaderAccess || user?.Person?.id === person?.id) {
      return true
    }
    return false
  }

  const onClose = item => {
    setEditedItem(undefined)
    if (!_.isEmpty(item)) setUpdate(true)
  }

  const deleteItem = async (item: IAllocation) => {
    try {
      await allocationAPI.delete(item.id)
      if (!isComponentMounted.current) return
      setUpdate(true)
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      <Grid sx={{ maxWidth: 1400, m: '0 auto' }}>
        <CardHeader
          title={
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent={checkCollapse() ? 'flex-start' : 'space-between'}
              spacing={1}
            >
              <Grid item>{t('allocation')}</Grid>
              {collapsible && !showMore && personId ? (
                <Grid item xs={7} md={6} lg={6} sx={{ marginRight: '30px', maxWidth: '680px' }}>
                  {!loading ? <AllocationTimeline personId={personId} data={allocations} /> : <CircularProgress />}
                </Grid>
              ) : (
                <Grid item style={{ color: colors.gray, marginLeft: '20px' }}>
                  {`(${person?.firstName} ${person?.lastName})`}
                </Grid>
              )}
            </Grid>
          }
          avatar={<AllocationIcon />}
          action={
            <Grid container alignContent="center" alignItems="center" item>
              {checkCollapse() && checkPermissions() && (
                <Grid>
                  <GreenAddButton
                    valid
                    clickAction={() => {
                      setEditedItem(null)
                    }}
                    label={t('allocation.addTitle')}
                  />
                </Grid>
              )}
              {collapsible && (
                <CaleoIconButton
                  clickAction={() => {
                    setShowMore(!showMore)
                  }}
                  tooltip={showMore ? t('showLess') : t('showMore')}
                  icon={showMore ? <ExpandLessIcon fontSize={'large'} /> : <ExpandMoreIcon fontSize={'large'} />}
                  size="medium"
                />
              )}
            </Grid>
          }
        />
        {((collapsible && showMore) || collapsible === false) && (
          <Grid>
            <AllocationCalendar items={allocations} />
            {user && user.Person && (
              <AllocationTable
                items={allocations}
                openItem={(item: IAllocation) => {
                  setEditedItem(item)
                }}
                deleteItem={deleteItem}
                personId={user.Person.id}
                salesAccess={salesAccess || teamLeaderAccess}
              />
            )}
          </Grid>
        )}
      </Grid>
      {editedItem !== undefined && (
        <AllocationModal
          item={editedItem}
          personId={personId}
          initialData={getAllocationInitialData(user?.Person?.id === personId)}
          schema={ownAllocationSchema()}
          maxWidth="sm"
          fullWidth={true}
          localeBase={'allocation'}
          api={allocationAPI}
          submitOnModal={true}
          onClose={item => {
            setUpdate(true)
            onClose(item)
          }}
          header="allocation"
          salesAccess={salesAccess}
          submitColor="primary"
        />
      )}
    </>
  )
}

export default PersonAllocationView
