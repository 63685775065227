import { cvAPI, cvEmployersAPI, cvRolesAPI } from 'api/CvAPI'
import React, { useMemo, useState, useEffect } from 'react'
import { ICv, IEmployer, IProject } from 'types/cvsInterfaces'
import withProfileCard from 'Components/reusable/HOC/withProfileCard'
import { getEmployerInitialData } from 'Components/reusable/DataContext/InitialData'
import { employerSchema } from 'Components/reusable/DataContext/ValidationSchema'
import EmployerItemCard from './EmployerItemCard'
import EmployerModal from './EmployerModal'
import { useTranslation } from 'react-i18next'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported   */
interface IEmployerCardProps {
  /** Is card editable. */
  editable: boolean
  /** CV data. */
  cv: ICv
  /** Function for updating CV updated at. */
  setUpdatedAt: () => void
  /** Close card function. */
  closeItem: () => void
  /** Replace or add CV data function. */
  replaceOrAddItem: (newItem) => IEmployer[]
  /** Open CV item function. */
  openItem: (item) => void
  /** Edited CV item. */
  editedItem: IEmployer | null | undefined
  /** Employer history items. */
  items: IEmployer[]
  /** Header text. */
  header: string
  /** Is card expanded. */
  expanded: boolean
  /** Set CV roles function. */
  setRoles: (items) => void
  /** Update CV overview data function. */
  updateOverviewData: (type: string, data: unknown | unknown[]) => void
  /** Update CV projects function. */
  updateProjects: (newProject: IProject) => void
  /** Is item anonymous. */
  anonymous?: boolean
  /** Update CV progress function. */
  updateProgress: () => void
}

/**
 * Employer history card.
 *
 * @returns Employer history card component.
 * @notExported
 */
const EmployerHistoryCard: React.FC<IEmployerCardProps> = ({
  cv,
  editable,
  items,
  setUpdatedAt,
  closeItem,
  replaceOrAddItem,
  openItem,
  editedItem,
  header,
  expanded,
  setRoles,
  updateOverviewData,
  updateProjects,
  anonymous = false,
  updateProgress,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const [updateConnected, setUpdateConnected] = useState<number>()

  useEffect(() => {
    const controller = new AbortController()

    if (updateConnected) {
      ;(async () => {
        const results = await cvRolesAPI.getList(updateConnected, controller)
        if (isComponentMounted.current) {
          setRoles(results)
          setUpdateConnected(undefined)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [updateConnected])

  const convertToProject = async (item: IEmployer) => {
    const result = await cvAPI.convertToProject(item.id)
    if (isComponentMounted.current) {
      setUpdatedAt()
      updateOverviewData('employers', replaceOrAddItem({ deleted: item.id }))
      updateProgress()
      setUpdateConnected(item.cvId)
      updateProjects(result)
      closeItem()
    }
  }

  const itemCards = useMemo(
    () =>
      items.map((item, i) => (
        <EmployerItemCard
          expanded={expanded}
          key={item.id}
          editable={editable}
          employer={item}
          onOpen={() => openItem(item)}
          header={header}
          anonymous={anonymous}
          showDivider={i < items.length - 1}
        />
      )),
    [items]
  )

  return (
    <>
      {itemCards}
      {editedItem !== undefined && (
        <EmployerModal
          key={editedItem?.id ?? 'new'}
          item={editedItem}
          cvId={cv.id}
          initialData={getEmployerInitialData(cv.id)}
          schema={employerSchema()}
          api={cvEmployersAPI}
          localeBase="profile.employer.modal"
          maxWidth="lg"
          fullWidth
          onClose={({ newItem }) => {
            if (newItem && (newItem.deleted || newItem.id)) {
              setUpdatedAt()
              updateOverviewData('employers', replaceOrAddItem(newItem))
              setUpdateConnected(newItem.cvId)
              updateProgress()
            }
            closeItem()
          }}
          extraButtonAction={(item: IEmployer) => convertToProject(item)}
          extraButtonLabel={t('profile.employer.model.convertToProject')}
          header={header}
        />
      )}
    </>
  )
}

export default withProfileCard(EmployerHistoryCard)
