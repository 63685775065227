import React, { ChangeEvent, useState } from 'react'
import PhoneInput from 'material-ui-phone-number'
import { Box, Grid, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface PhoneNumberInputProps extends Omit<TextFieldProps, 'value' | 'onChange' | 'defaultValue' | 'error'> {
  value?: unknown
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  insetLabel?: boolean
}

/**
 * Phone number input component.
 *
 * @param value - the current value.
 * @param onChange - the change handler.
 * @param error - the error state.
 * @returns the phone number input component.
 * @notExported
 */
const PhoneNumberInput: React.FC<PhoneNumberInputProps> = props => {
  const { t } = useTranslation()
  const { id, helperText, value, onChange, error, label, required, insetLabel, ...rest } = props
  const [phoneNumber, setPhoneNumber] = useState<string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>(
    (value as string) || ''
  )

  const handlePhoneNumberChange = (value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPhoneNumber(value)
    if (onChange) {
      const event = {
        target: {
          value: value,
          id: id,
        },
      } as React.ChangeEvent<HTMLInputElement>
      onChange(event)
    }
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPhoneNumber(value)
    if (onChange) {
      onChange(event)
    }
  }

  const phoneNumberHelperText = () => {
    if (helperText) {
      return (
        <Grid
          sx={{
            marginLeft: '12px',
            '&.Mui-error': {
              color: theme => theme.palette.error.main,
            },
          }}
        >
          {helperText}
        </Grid>
      )
    } else {
      return null
    }
  }

  return (
    <Grid sx={{ display: 'flex', alignItems: 'baseline', flexDirection: 'column' }}>
      <PhoneInput
        defaultCountry="fi"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        error={error}
        margin="dense"
        variant="outlined"
        size="small"
        id={id}
        helperText={''}
        label={
          insetLabel ? (
            <>
              {label}
              <span style={{ color: '#BE3C3C', fontSize: '0.85em', marginLeft: '5px' }}>
                {required && `${t('required')}`}
              </span>
            </>
          ) : undefined
        }
        InputLabelProps={insetLabel ? { shrink: true } : {}}
        fullWidth
        {...rest}
      />
      {phoneNumberHelperText()}
      <TextField
        id={id}
        sx={{ display: 'none' }}
        helperText={''}
        error={error}
        value={phoneNumber}
        onChange={handleTextFieldChange}
        margin="dense"
        variant="outlined"
        size="small"
        label={
          insetLabel ? (
            <>
              {label}
              <Box sx={{ color: '#BE3C3C', fontSize: '0.85em', marginLeft: '5px' }}>
                {required && `${t('required')}`}
              </Box>
            </>
          ) : undefined
        }
        InputLabelProps={insetLabel ? { shrink: true } : {}}
        fullWidth
      />
    </Grid>
  )
}

export default PhoneNumberInput
