import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Checkbox,
  Collapse,
  DialogContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import newWithConfirmationModal from 'Components/reusable/HOC/newWithConfirmationModal'
import { useAllocations } from 'hooks/allocations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployee } from 'types/adminInterfaces'
import { IAllocation } from 'types/allocationInterfaces'
import { IError } from 'types/error'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'

/**
 * @notExported
 */
interface IDeleteEmployeeConfirmProps {
  /** Employee being deleted */
  item: IEmployee
  /** If the employee is being deleted from the network management system */
  networkManage?: boolean
}

/**
 * Component for confirming employee deletion
 *
 * @returns Confirmation modal for employee deletion
 * @notExported
 */
const DeleteEmployeeConfirm: React.FC<IDeleteEmployeeConfirmProps> = ({ item, networkManage }) => {
  const { t, i18n } = useTranslation()
  const { allocations, error } = useAllocations(item.Person.id)
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    if (error) setBackendError(error as IError)
  }, [error])

  /**
   * Get name or type of allocation.
   *
   * @param allocation - Current allocation
   * @returns Name of assignment or type of allocation
   * @notExported
   */
  const getName = (allocation: IAllocation) => {
    if (allocation.type === 'project') {
      if (allocation.Assignment && allocation.Assignment.translations) {
        return chooseDBTranslation(i18n, allocation.Assignment).name
      }
      return t('allocation.project')
    } else if (allocation.type === 'personal') {
      return t('allocation.ownProject')
    } else {
      return t('allocation.other')
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent>
      <Typography color="primary" fontWeight="bold" ml={3} variant="h2">
        {t('confirmation.user.data')}
      </Typography>
      <p style={{ marginLeft: '15px' }}>{t('confirmation.text')}</p>
      {networkManage && (
        <>
          <p style={{ marginLeft: '15px' }}>{t('confirmation.networkManage')}</p>
          <p style={{ marginLeft: '15px' }}>{t('confirmation.networkManage.confirm')}</p>
        </>
      )}
      {allocations.length > 0 ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setDetailsOpen(!detailsOpen)}
                icon={<ExpandMoreIcon />}
                checkedIcon={<ExpandLessIcon />}
                name="collapseCh"
              />
            }
            label={t('allocation')}
          />
          <Collapse in={detailsOpen}>
            <Table aria-label="allocation table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('allocation.project')}</TableCell>
                  <TableCell align="right">{t('allocation.startDate')}</TableCell>
                  <TableCell align="right">{t('allocation.endDate')}</TableCell>
                  <TableCell align="right">{t('allocation.information')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allocations.map((allocation: IAllocation) => (
                  <TableRow key={allocation.id}>
                    <TableCell variant="head" scope="row">
                      {getName(allocation)}
                    </TableCell>
                    <TableCell align="right">{convertToDisplayDate(allocation.startDate)}</TableCell>
                    <TableCell align="right">{convertToDisplayDate(allocation.endDate)}</TableCell>
                    <TableCell align="right">{chooseDBTranslation(i18n, allocation).information}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </>
      ) : null}
      <p style={{ marginLeft: '15px' }}>
        {t('no-undo')} {t('confirmation.user.text')}
      </p>
    </DialogContent>
  )
}

export default newWithConfirmationModal(DeleteEmployeeConfirm)
