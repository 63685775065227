import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { teamAPI } from 'api/TeamAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { getTeamInitialData } from 'Components/reusable/DataContext/InitialData'
import { teamSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { ITeam } from 'types/teamInterfaces'
import TeamCard from './TeamCard'
import TeamModal from './TeamModal'
import TeamViewPage from './TeamViewPage'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { personAPI } from 'api/PersonAPI'
import { permissionAPI } from 'api/PermissionAPI'
import { orderBy } from 'lodash'
import { ISkill, IRole, IIndustry } from 'types/cvsInterfaces'
import FilterFields from './FilterFields'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'
import AddIcon from '@mui/icons-material/Add'

/**
 * Renders the Team component.
 *
 * @return {JSX.Element} The rendered Team component.
 * @notExported
 */
const Team = () => {
  const isComponentMounted = useIsComponentMounted()
  const { user, groups } = useUser()
  const { t } = useTranslation()
  const [editedItem, setEditedItem] = useState<ITeam | null | undefined>()
  const [teams, setTeams] = useState<ITeam[]>([])
  const [backendError, setBackendError] = useState<IError>()
  const [viewItem, setViewItem] = useState<ITeam | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [allTeams, setAllTeams] = useState<boolean>(false)
  const [images, setImages] = useState<{ accountId: number; image: string }[]>([])
  const [leaderAccess, setLeaderAccess] = useState<number[]>([])
  const [skillFilter, setSkillFilter] = useState<ISkill[]>([])
  const [roleFilter, setRoleFilter] = useState<IRole[]>([])
  const [industryFilter, setIndustryFilter] = useState<IIndustry[]>([])
  const [certificateFilter, setCertificateFilter] = useState<string[]>([])
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<ITeam[]>([])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (user && groups) {
        try {
          let teamsData
          setLoading(true)
          if (groups.includes('admin') && allTeams) {
            teamsData = await teamAPI.getAllTeams(controller)
          } else if (groups.includes('sales') || groups.includes('companyAdmin')) {
            teamsData = await teamAPI.getSalesTeams(controller)
          } else {
            teamsData = await teamAPI.getUserTeams(controller)
          }
          if (!isComponentMounted.current) return
          setTeams(orderBy(teamsData, 'organizationId', 'asc'))
        } catch (err) {
          setBackendError(err as IError)
        } finally {
          if (isComponentMounted.current) setLoading(false)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [groups, user, allTeams])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      const imagesMap: { accountId: number; image: string }[] = []
      for (const team of teams) {
        for (const member of team.TeamMembers) {
          if (
            member.Account &&
            member.Account.Person &&
            !imagesMap.find(image => image.accountId === member.Account.id)
          ) {
            const image = await personAPI.getProfileImage(member.Account.Person.id, controller)
            if (!isComponentMounted.current) return
            imagesMap.push({ accountId: member.Account.id, image: image ?? '' })
          }
        }
      }
      if (!isComponentMounted.current) return
      setImages(imagesMap)
      if (teams.length > 0) {
        const results = await permissionAPI.getLeaderAccessToTeams(
          teams.map(team => team.id),
          controller
        )
        if (!isComponentMounted.current) return
        setLeaderAccess(results)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [teams])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoadingFilters(true)
        if (teams.length) {
          const results = await teamAPI.filterTeams(
            teams.map(item => item.id),
            skillFilter,
            roleFilter,
            industryFilter,
            certificateFilter,
            controller
          )
          if (!isComponentMounted.current) return
          setFiltered(results)
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) setLoadingFilters(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [teams, skillFilter, roleFilter, industryFilter, certificateFilter])

  const checkTeams = () => {
    if (!loading && teams.length === 0) {
      return (
        <Box p={{ md: 3, sm: 1, xs: 0 }} mt={{ xs: 1 }}>
          <CardContentText>{t('team.noTeam')}</CardContentText>
        </Box>
      )
    }
  }

  const replaceOrAddItem = newItem => {
    const newTeams = [...teams]
    if (newItem.id) {
      const index = newTeams.findIndex(team => team.id === newItem.id)

      if (index > -1) {
        newTeams[index] = newItem
      } else {
        newTeams.push(newItem)
      }

      if (viewItem) {
        setViewItem(newItem)
      }
    }
    if (newItem.deleted) {
      const index = newTeams.findIndex(team => team.id === newItem.deleted)

      if (index > -1) {
        newTeams.splice(index, 1)
      }

      if (viewItem) {
        setViewItem(undefined)
      }
    }

    setTeams(newTeams)
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const adminOrSalesAccount = groups.includes('sales') || groups.includes('companyAdmin') || groups.includes('admin')

  return (
    <Box p={{ md: 3, sm: 1, xs: 0 }} sx={{ background: colors.searchBackground, margin: '0 auto', maxWidth: 1400 }}>
      {!viewItem && (
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs="auto">
              <Typography variant="h1" gutterBottom fontWeight="bold">
                {t('team.title')}
              </Typography>
            </Grid>
            {adminOrSalesAccount && (
              <Grid item xs="auto">
                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setEditedItem(null)}>
                  {t('team.modal.addTitle')}
                </Button>
              </Grid>
            )}
          </Grid>
          {(loading || loadingFilters) && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={1}>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {checkTeams()}
          <FilterFields
            setSkillFilter={setSkillFilter}
            setRoleFilter={setRoleFilter}
            setIndustryFilter={setIndustryFilter}
            setCertificateFilter={setCertificateFilter}
            allItems={allTeams}
            setAllItems={setAllTeams}
          />
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight="bold">
              {t('team.listHeader')}
            </Typography>
          </Grid>
          <Grid item xs={12} container direction="row" spacing={2}>
            {filtered &&
              filtered.map((team: ITeam) => (
                <TeamCard
                  key={team.id}
                  item={team}
                  images={images}
                  setEditedItem={setEditedItem}
                  adminAccount={adminOrSalesAccount}
                  managerAccess={!!leaderAccess.find(id => team.id === id)}
                  setViewItem={setViewItem}
                />
              ))}
          </Grid>
        </Grid>
      )}
      {viewItem && (
        <TeamViewPage
          key={viewItem.id}
          item={viewItem}
          adminAccount={adminOrSalesAccount}
          managerAccess={!!leaderAccess.find(id => viewItem.id === id)}
          onClose={() => {
            setViewItem(undefined)
          }}
          setEdit={item => setEditedItem(item)}
        />
      )}
      {editedItem !== undefined && (
        <TeamModal
          item={editedItem}
          maxWidth="md"
          initialData={getTeamInitialData(user?.organizationId)}
          fullWidth={true}
          localeBase="team.modal"
          schema={teamSchema()}
          api={teamAPI}
          submitOnModal={true}
          onClose={({ newItem }) => {
            setEditedItem(undefined)
            if (newItem) {
              replaceOrAddItem(newItem)
            }
          }}
          header="teams"
          noEscClose
          submitColor="primary"
        />
      )}
    </Box>
  )
}

export default Team
