import { cvEducationsAPI } from 'api/CvAPI'
import { orderBy } from 'lodash'
import React from 'react'
import { ICertificate, ICourse, ICv, IEducation } from 'types/cvsInterfaces'
import withProfileCard, { Data } from 'Components/reusable/HOC/withProfileCard'
import { getEducationInitialData } from 'Components/reusable/DataContext/InitialData'
import { educationSchema } from 'Components/reusable/DataContext/ValidationSchema'
import EducationItemCard from './EducationItemCard'
import EducationModal from './EducationModal'

/** @notExported */
interface IEducationCardProps {
  /** Is card editable. */
  editable: boolean
  /** CV object. */
  cv: ICv
  /** Function to set updated at. */
  setUpdatedAt: () => void
  /** Replace or add data function. */
  replaceOrAddData: (newItem, type) => Data
  /** Function to open item. */
  openItem: (item) => void
  /** Edited item. */
  editedItem: IEducation | null
  /** Function to close item. */
  closeItem: () => void
  /** Header. */
  header: string
  /** Data object. */
  data: { education: IEducation[]; courses: ICourse[]; certificates: ICertificate[] }
  /** Function to update overview data. */
  updateOverviewData: (type: string, data: unknown | unknown[]) => void
  /** Is item anonymous. */
  anonymous?: boolean
  /** Function to update progress indicator. */
  updateProgress: () => void
}

/**
 * Education card.
 *
 * @returns Education card component.
 * @notExported
 */
const EducationCard: React.FC<IEducationCardProps> = ({
  cv,
  editable,
  setUpdatedAt,
  closeItem,
  replaceOrAddData,
  openItem,
  editedItem,
  header,
  data,
  updateOverviewData,
  anonymous = false,
  updateProgress,
}) => {
  const checkType = (item): string | undefined => {
    let data = item
    if (data.deleted) {
      data = item.item
    }

    if ('degree' in data.translations[0]) {
      return 'education'
    } else if ('course' in data.translations[0]) {
      return 'courses'
    } else if ('certificate' in data.translations[0]) {
      return 'certificates'
    }
  }

  return (
    <>
      <EducationItemCard
        color="success"
        title="degrees"
        educations={orderBy(data.education, ['endDate', 'startDate'], ['desc', 'desc'])}
        editable={editable}
        openItem={openItem}
        anonymous={anonymous}
      />
      <EducationItemCard
        color="warning"
        title="courses"
        courses={orderBy(data.courses, ['endDate', 'startDate'], ['desc', 'desc'])}
        editable={editable}
        openItem={openItem}
        anonymous={anonymous}
      />
      <EducationItemCard
        color="info"
        title="certificates"
        certificates={orderBy(data.certificates, ['endDate', 'startDate'], ['desc', 'desc'])}
        editable={editable}
        openItem={openItem}
        anonymous={anonymous}
      />
      {editedItem !== undefined && (
        <EducationModal
          key={editedItem?.id ?? 'new'}
          cvId={cv.id}
          item={editedItem}
          localeBase={'profile.education.modal'}
          schema={educationSchema()}
          initialData={getEducationInitialData(cv.id)}
          maxWidth="md"
          fullWidth={true}
          api={cvEducationsAPI}
          onClose={({ newItem }) => {
            closeItem()
            if (newItem) {
              setUpdatedAt()
              const type = checkType(newItem)
              if (type) {
                updateOverviewData(type, replaceOrAddData(newItem, type))
                updateProgress()
              }
            }
          }}
          header={header}
        />
      )}
    </>
  )
}

export default withProfileCard(EducationCard)
