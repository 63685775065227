import { getForgotPasswordInitialData } from 'Components/reusable/DataContext/InitialData'
import { forgotPasswordSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { authAPI } from 'api/AuthAPI'
import { IForgotPassword } from 'types/accountInterfaces'
import InformativeModal from './InformativeModal'
import { Grid } from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useLogin, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { IError } from 'types/error'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import LoginPageCard from 'Components/reusable/CaleoCustomComponents/LoginPageCard'
import LoginPageCardWrapper from 'Components/reusable/CaleoCustomComponents/LoginPageCardWrapper'
import { useTranslation } from 'react-i18next'
import { useIsComponentMounted } from 'hooks/util'

type T = IForgotPassword

/**
 * Renders the ForgotPassword component.
 *
 * @return {React.FC} The rendered component.
 * @notExported
 */
const ForgotPassword: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [submitError, setSubmitError] = useState<IError>()
  const [isResetAsked, setIsResetAsked] = useState<boolean>(false)

  const { setResetPassword } = useLogin()

  const [data, setData] = useState<NewOrExisting<T>>(getForgotPasswordInitialData())
  const schema = forgotPasswordSchema()
  const localeBase = 'forgotPassword'
  const [submitLocally, setSubmitLocally] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()
  const { loggingIn, loggedIn, loginLoading } = useLogin()
  const location = useLocation()
  const { groupsReady, groups } = useUser()

  const buttonsEnabled = !loggingIn

  useEffect(() => {
    if (loggedIn && groupsReady) {
      if (location.state?.url) {
        return navigate(location.state?.url)
      } else if (groups && groups.includes('sales')) {
        return navigate('/employees')
      } else {
        return navigate('/profile')
      }
    }
  }, [loggedIn, groupsReady])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (submitLocally) {
        setResetPassword(true)
        try {
          await authAPI.forgotPassword(data, controller)
          if (!isComponentMounted.current) return
          setIsResetAsked(true)
        } catch (err) {
          setSubmitError(err as IError)
          setSubmitLocally(false)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitLocally])

  if (!data) {
    return <LoadingIndicator />
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (submitError) {
    return <ErrorOverlay error={submitError} setOpen={setSubmitError} />
  }

  const onClose = () => {
    setResetPassword(false)
    navigate('/login', { state: { search: '' } })
  }

  return (
    <LoginPageCardWrapper>
      {loginLoading ? (
        <LoadingIndicator />
      ) : (
        <DataContext
          data={data}
          onChange={setData}
          onSubmit={() => setSubmitLocally(true)}
          schema={schema}
          localeBase={localeBase}
        >
          <LoginPageCard header={t('forgotPassword')}>
            <Grid container>
              <Grid item xs={12} sx={{ marginTop: '16px', marginBottom: '16px' }}>
                <DataContext.TextField<IForgotPassword> field="email" required fullWidth type="email" />
                {isResetAsked && (
                  <InformativeModal
                    header={t('resetasked-header')}
                    allowSubmit={t('resetasked-ok')}
                    onClose={onClose}
                    goBack={() => {
                      setResetPassword(false)
                      navigate('/login', { state: { search: '' } })
                    }}
                    maxWidth="sm"
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1} alignItems="center">
              <Grid item xs={12} container alignItems="center" spacing={1}>
                <Grid item xs="auto">
                  <DataContext.Validation>
                    {({ valid }) => (
                      <CaleoPrimaryButton
                        valid={valid || !buttonsEnabled}
                        clickAction={() => setSubmitLocally(true)}
                        label={t('submit')}
                        loading={false}
                      />
                    )}
                  </DataContext.Validation>
                </Grid>
              </Grid>
            </Grid>
          </LoginPageCard>
        </DataContext>
      )}
    </LoginPageCardWrapper>
  )
}

export default ForgotPassword
