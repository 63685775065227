import { Dialog, DialogActions } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CaleoSecondaryButton from '../Buttons/CaleoSecondaryButton'

/**
 * Confirmation modal higher order component.
 *
 * @param ModalComponent - Modal component to wrap.
 * @returns Confirmation modal component.
 * @notExported
 */
const withConfirmationModal = ModalComponent => {
  const ConfirmationModal = props => {
    const { t } = useTranslation()
    const [disableDelete, setDisableDelete] = useState<boolean>(false)

    return (
      <>
        {props.open && (
          <Dialog open onClose={() => props.onClose({})} maxWidth={props.maxWidth} fullWidth={props.fullWidth}>
            <ModalComponent {...props} disableDelete={value => setDisableDelete(value)} />
            <DialogActions
              sx={{ background: theme => theme.palette.background.paper, paddingRight: '22px', paddingBottom: '22px' }}
            >
              <CaleoSecondaryButton
                label={props.noLabel ? t(props.noLabel) : !disableDelete ? t('confirmation.no') : t('confirmation.ok')}
                valid={true}
                clickAction={props.onClose}
              />
              {!disableDelete && (
                <CaleoSecondaryButton
                  label={props.yesLabel ? t(props.yesLabel) : t('confirmation.yes')}
                  valid={true}
                  clickAction={props.confirm}
                />
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
  return ConfirmationModal
}

export default withConfirmationModal
